import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import Footer from "../../../generic/Footer";
import Header from "../../../generic/Header";
import { Autocomplete, Box, Breadcrumbs, Paper } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import ScheduleIcon from "@mui/icons-material/Schedule";
import mobileNoValidation, { countryCodeList } from "../../../../common/common";
import SendIcon from "@mui/icons-material/Send";
import { Bounce, toast } from "react-toastify";
import {
  Grid,
  FormControl,
  InputAdornment,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { config } from "../../../../config/config";
import { apiList, invokeApi } from "../../../../apis/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { getLocation, getUser } from "../../../../global/redux/action";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const PackersMovers = () => {
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { locationData, locationError, isLocationFetching } = useSelector(
    (state) => state.location
  );

  const { userData, userError, isFetching } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (
      cookies[config.cookieName]?.loginUserId &&
      !userData &&
      !isFetching &&
      !userError
    ) {
      dispatch(
        getUser({ id: cookies[config.cookieName]?.loginUserId, cookies })
      );
    }
    if (!locationData?.countries && !isLocationFetching && !locationError) {
      dispatch(
        getLocation({ id: cookies[config.cookieName]?.loginUserId, cookies })
      );
    }
  }, [
    dispatch,
    cookies,
    userData,
    isFetching,
    userError,
    locationError,
    locationData,
    isLocationFetching,
  ]);

  const zoneMappingData = locationData?.countries;

  const [leadLoading, setleadLoading] = useState(false);

  const [email, setEmail] = useState(userData?.user?.email || "");

  const [customerName, setcustomerName] = useState(
    userData?.user?.fullName || ""
  );
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");

  const [mobileNumber, setMobileNumber] = useState(
    userData?.user?.mobileNumber || ""
  );
  let mobileValidate = mobileNoValidation(mobileNumber);
  const [mobileError, setMobileError] = useState(false);
  const [mobileHelperText, setMobileHelperText] = useState("");

  const [scheduleDateTime, setScheduleDateTime] = useState(null);
  const [countryCode, setCountryCode] = useState(
    userData?.user?.countryCode || "91"
  );

  const [selectedState, setSelectedState] = useState(
    userData?.user?.state || null
  );
  const [stateError, setStateError] = useState(false);
  const [stateHelperText, setStateHelperText] = useState("");

  const [selectedDistrict, setSelectedDistrict] = useState(
    userData?.user?.district || null
  );
  const [cityError, setCityError] = useState(false);
  const [cityHelperText, setCityHelperText] = useState("");

  const addLead = async () => {
    setleadLoading(true);
    let validate = true;

    if (!mobileValidate) {
      setMobileError(true);
      setMobileHelperText("Please Enter Valid Mobile Number");
      setleadLoading(false);
      validate = false;
    }
    if (!selectedState) {
      setStateError(true);
      setStateHelperText("Please Select State");
      setleadLoading(false);
      validate = false;
    }
    if (!selectedDistrict) {
      setCityError(true);
      setCityHelperText("Please Select City");
      setleadLoading(false);
      validate = false;
    }
    if (!customerName) {
      setNameError(true);
      setNameHelperText("Please Enter Your Full Name");
      setleadLoading(false);
      validate = false;
    }
    if (validate) {
      let params = {
        email: email,
        customerName,
        mobileNumber,
        // propertyId: propertyId,
        leadSource: "Packers Movers",
        leadType: "Own",
        scheduleDateTime,
        countryCode: countryCode,
        customerDistrict: selectedDistrict,
        customerState: selectedState,
      };
      try {
        const response = await invokeApi(
          config.apiDomains + apiList.addLead,
          params,
          cookies
        );

        if (response) {
          if (
            response.status === "200" ||
            response.data.responseMessage === "Successful"
          ) {
            toast.success(
              "Thank you for showing interest we will contact you soon",
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              }
            );
            setleadLoading(false);
            setcustomerName("");
            setMobileNumber("");
            setEmail("");
          } else {
            toast.error("Sorry Something went wrong", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
            setleadLoading(false);
            setcustomerName("");
            setMobileNumber("");
            setEmail("");
          }
        } else {
          alert("Something Went Wrong");
          setleadLoading(false);
        }
      } catch (error) {
        toast.error("An error occurred while fetching location!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setleadLoading(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Get My Housing - Packers and Movers Service</title>
        <meta
          property="og:title"
          content="Get My Housing - Packers and Movers Service"
        />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
        <meta
          property="og:image"
          content={`https://getmyhousing.com/servicePhotos/Packers%20Movers.jpg`}
        />
      </Helmet>
      <Header />
      <Box
        sx={{
          minHeight: "100svh",
          width: "100%",
          textAlign: "center",
          p: 5,
          mt: { md: "101px", xs: "72px" },
        }}
      >
        <Box sx={{ mb: 4, textAlign: "left", width: "100%" }}>
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#f44336", // Red primary color
              fontSize: { md: "1rem", xs: "0.875rem" },
              "& .MuiBreadcrumbs-separator": {
                color: "#d32f2f", // Darker red for separator
              },
            }}
          >
            <Typography
              sx={{
                color: "#f44336",
                cursor: "pointer",
                fontWeight: "bold",
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={() => navigate("/")}
            >
              Home
            </Typography>
            <Typography
              sx={{
                color: "#d32f2f",
                cursor: "pointer",
                fontWeight: "bold",
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={() => navigate("/")}
            >
              Services
            </Typography>
            <Typography
              sx={{
                color: "#b71c1c", // Darker red for the current item
                fontWeight: "bold",
              }}
            >
              Packers & Movers
            </Typography>
          </Breadcrumbs>
        </Box>
        <Typography
          variant="h2"
          sx={{
            fontSize: { md: "2.5rem", xs: "2rem" },
            fontWeight: "700",
            marginBottom: "20px",
            color: "#333",
          }}
        >
          Packers & Movers
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: { md: "1.2rem", xs: "1rem" },
            marginBottom: "30px",
            color: "#666",
          }}
        >
          Simplify your relocation with our reliable and efficient Packers & Movers services, ensuring a hassle-free move.
        </Typography>
        <Grid container component={Paper} elevation={3} sx={{
          p: 3,
          borderRadius: "20px",
          maxWidth: "1200px",
          mx: "auto",
          gap: 3,
        }}>
          {/* Code for the Image Store */}
          <Grid item md={5.5} xs={12}>
            <img
              src="/servicePhotos/Packers Movers.jpg"
              alt="Example"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: "60px",
              }}
            />
          </Grid>

          {/* Code for the Registration form  */}
          <Grid item md={6} xs={12}>
            <Typography variant="h5">Enquire Now</Typography>
            <Grid item xs={11} sx={{ ml: 2 }}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Your Name"
                    margin="dense"
                    size="small"
                    type="text"
                    value={customerName}
                    error={nameError}
                    helperText={nameHelperText}
                    onChange={(event) => setcustomerName(event.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth variant="outlined">
                    {/* <InputLabel id="demo-customized-select-label">
                      Country Code
                    </InputLabel> */}
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      size="small"
                      value={countryCode}
                      onChange={(event) => setCountryCode(event.target.value)}
                    // input={<OutlinedInput label="Country Code" />}
                    >
                      {countryCodeList?.map((item, index) => (
                        <MenuItem key={index} value={item.code}>
                          {"+ " + item.code}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                    // label="Mobile Number"
                    placeholder="Enter your mobile number"
                    inputProps={{ maxLength: 10 }}
                    error={mobileError}
                    helperText={mobileHelperText}
                    value={mobileNumber}
                    onChange={(event) => setMobileNumber(event.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="email"
                    margin="dense"
                    size="small"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="scheduleDateTime"
                    type="datetime-local"
                    size="small"
                    placeholder="Schedule Date & Time"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={scheduleDateTime}
                    onChange={(e) => setScheduleDateTime(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ScheduleIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="outlined-select-country"
                    options={
                      zoneMappingData
                        ?.find((item) => item.countryName === "India")
                        ?.states.map((ite) => ite.stateName) || []
                    }
                    value={selectedState}
                    onChange={(event, newValue) => {
                      setSelectedState(newValue);
                      setStateError(false);
                      setStateHelperText("");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={stateError}
                        size="small"
                        fullWidth
                        helperText={stateHelperText}
                        label="Please select your state"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="outlined-select-district"
                    options={
                      (
                        zoneMappingData
                          ?.find((item) => item.countryName === "India")
                          ?.states?.find(
                            (state) => state.stateName === selectedState
                          )?.districts || []
                      ).map((district) => district.districtName) || []
                    }
                    value={selectedDistrict}
                    disabled={!selectedState}
                    onChange={(event, newValue) => {
                      setSelectedDistrict(newValue);
                      setCityError(false);
                      setCityHelperText("");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={cityError}
                        size="small"
                        fullWidth
                        helperText={cityHelperText}
                        label="Please select your city / district"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <LoadingButton
                size="large"
                onClick={addLead}
                endIcon={<SendIcon />}
                loading={leadLoading}
                loadingPosition="end"
                variant="contained"
                sx={{
                  mt: 1,
                  backgroundColor: "#f44336", // Red color
                  color: "#FFF", // White text color
                  "&:hover": {
                    backgroundColor: "#d32f2f", // Darker red on hover
                  },
                  "&:disabled": {
                    backgroundColor: "#ef9a9a", // Light red for disabled state
                    color: "#616161", // Dark gray text color for disabled state
                  },
                }}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default PackersMovers;
