import { useCookies } from "react-cookie";
import { Navigate, useLocation } from "react-router-dom";
import { config } from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getUser } from "../../global/redux/action";

const ProtectedRoute = ({ children }) => {
  const [cookies] = useCookies([config.cookieName]);
  const dispatch = useDispatch();
  const location = useLocation();
  const [hasFetched, setHasFetched] = useState(false); // Prevents multiple API calls

  const { userData, userError, isFetching } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (
      cookies[config.cookieName]?.loginUserId &&
      !userData?.user &&
      !userError &&
      !isFetching
    ) {
      dispatch(
        getUser({ id: cookies[config.cookieName]?.loginUserId, cookies })
      );
      setHasFetched(true); // Mark that fetch has been initiated
    }
  }, [cookies, userData, isFetching, hasFetched]);


  // Redirect if user is inactive (only after data is fully loaded)
  if (userData?.user && userData.user.status !== "Active") {
    return <Navigate to="/inactive" replace={true} state={{ redirectTo: location.pathname }} />;
  }

  // Redirect if not logged in
  if (
    !(
      !!cookies[config.cookieName] &&
      !!cookies[config.cookieName].token &&
      !!cookies[config.cookieName].loginUserId
    )
  ) {
    return (
      <Navigate
        to="/"
        replace={true}
        state={{ redirectTo: location.pathname }}
      />
    );
  }

  return children;
};

export default ProtectedRoute;
