import { Box, Container, Grid, Typography } from '@mui/material'
import { Bounce, toast } from 'react-toastify';
import { config } from '../../../../config/config';
import { apiList, invokeApi } from '../../../../apis/apiServices';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import mobileNoValidation from '../../../../common/common';
import { useCookies } from 'react-cookie';

const HeroSection = ({ leadSource, bannerImage }) => {
    const [cookies] = useCookies();
    const { userData } = useSelector(
        (state) => state.user
    );
    const { locationData } = useSelector(
        (state) => state.location
    );
    const zoneMappingData = locationData?.countries;
    const [formData, setFormData] = useState({
        email: userData?.user?.email || "",
        customerName: userData?.user?.fullName || "",
        mobileNumber: userData?.user?.mobileNumber || "",
        leadSource,
        leadType: "Own",
        customerDistrict: userData?.user?.district || "",
        customerState: userData?.user?.state || "",
    })

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    let mobileValidate = mobileNoValidation(formData.mobileNumber);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        setErrors({ ...errors, [e.target.name]: "" })
    };

    // Validate form
    const validateForm = () => {
        let newErrors = {};

        if (!formData.customerName.trim()) newErrors.customerName = "Full Name is required.";

        // Mobile number validation (10 digits)
        if (!mobileValidate) {
            newErrors.mobileNumber = "Mobile Number is required.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const addLead = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        setIsSubmitting(true);

        try {
            const response = await invokeApi(
                config.apiDomains + apiList.addLead,
                formData,
                cookies
            );

            if (response) {
                if (
                    response.status === "200" ||
                    response.data.responseMessage === "Successful"
                ) {
                    toast.success(
                        "Thank you for showing interest we will contact you soon",
                        {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Bounce,
                        }
                    );
                    setFormData({
                        email: "",
                        customerName: "",
                        mobileNumber: "",
                        leadSource: "Property Management",
                        leadType: "Own",
                        customerDistrict: "",
                        customerState: "",
                    })
                    setIsSubmitting(false);
                } else {
                    toast.error("Sorry Something went wrong", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                    setIsSubmitting(false);
                }
            } else {
                alert("Something Went Wrong");
                setIsSubmitting(false);
            }
        } catch (error) {
            toast.error("An error occurred while fetching location!", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setIsSubmitting(false);
        }
    };
    return (
        <Box component="section" className="hero-section" sx={{ backgroundImage: `url(${bannerImage})`, mt: { md: "101px", xs: "72px" } }}>
            <Box className="hero-overlay"></Box>
            <Container maxWidth="lg">
                <Grid container spacing={6} alignItems="center">
                    <Grid item xs={12} md={6} className="hero-content">
                        <Typography variant="h3" className="hero-title" sx={{ mb: 2 }}>
                            Simplify Your Property Management
                        </Typography>
                        <Typography variant="body1" className="hero-text" sx={{ mb: 2 }}>
                            Manage your properties effortlessly with our all-in-one solution.
                            From tenant tracking to maintenance requests, we streamline the process for you.
                        </Typography>
                        <ul className="hero-list" style={{ marginBottom: "24px" }}>
                            <li>✅ Hassle-Free Tenant Management</li>
                            <li>✅ Automated Rent Collection</li>
                            <li>✅ Expert Legal & Financial Assistance</li>
                            <li>✅ 24/7 Property Maintenance Support</li>
                        </ul>
                        <a href="#contact" className="hero-button">Get Started</a>
                    </Grid>

                    <Grid item xs={12} md={6} className="hero-form-container" sx={{ display: "flex", justifyContent: "end" }}>
                        <form class="form" onSubmit={addLead}>
                            <p class="form-title">Get Professional Property Management Services</p>

                            <div class="input-container">
                                <input placeholder="Full Name *" type="text" value={formData.customerName} name="customerName" onChange={handleChange} />
                                {errors.customerName && <p className="error">{errors.customerName}</p>}
                            </div>

                            <div class="input-container">
                                <input placeholder="Phone Number *" type="tel" name="mobileNumber" value={formData.mobileNumber}
                                    onChange={handleChange} />
                                {errors.mobileNumber && <p className="error">{errors.mobileNumber}</p>}
                            </div>
                            <div class="input-container">
                                <input placeholder="Enter email" type="email" name="email" value={formData.email}
                                    onChange={handleChange} />
                            </div>
                            <div className="input-container">
                                <select value={formData.customerState} name="customerState" onChange={handleChange}>
                                    <option value="">Select State</option>
                                    {zoneMappingData?.find((item) => item.countryName === "India")
                                        ?.states.map((item, index) => (
                                            <option key={index} value={item.stateName}>{item.stateName}</option>
                                        ))}
                                </select>
                            </div>
                            <div className="input-container">
                                <select required
                                    name="customerDistrict"
                                    onChange={handleChange}
                                    value={formData.customerDistrict}
                                    disabled={!formData.customerState}
                                >
                                    <option value="">Select District</option>
                                    {zoneMappingData
                                        ?.find((item) => item.countryName === "India")
                                        ?.states?.find((state) => state.stateName === formData.customerState)
                                        ?.districts?.map((district, index) => (
                                            <option key={index} value={district.districtName}>
                                                {district.districtName} {/* Access district name properly */}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <button class="submit" type="submit" disabled={isSubmitting}>
                                {isSubmitting ? "Submitting..." : "Request a Consultation"}
                            </button>
                            <p class="signup-link">
                                Need urgent help?
                                <a href="tel:9686522212"> Contact us</a>
                            </p>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default HeroSection