import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { config } from "../../config/config";
import EastIcon from "@mui/icons-material/East";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { apiList, invokeApi } from "../../apis/apiServices";
import { PriceFormatter, formatSegment } from "../../common/common";
import { Link, useNavigate } from "react-router-dom";
import LeadModal from "../end-user/LeadModal";
// import required modules
import "./style.css";
import { Pagination, Navigation } from "swiper/modules";
const emptyPropertyImage = "/media/images/gridimgae.jpeg";

const ExclusivePropertySection = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();

  const [noOfResult, setNoOfResult] = useState(4);

  const [leadModal, setLeadModal] = useState(false);
  const [leadSource, setLeadSource] = useState("");
  const [propertyId, setPropertyId] = useState(null);

  const [isPropertiesFetching, setIsPropertiesFetching] = useState(true);
  const [propertiesList, setPropertiesList] = useState([]);

  const handeChangeModal = () => setLeadModal((prevState) => !prevState);

  // const displayBedrooms = (numOfBedrooms) => {
  //   // const { numOfBedrooms } = property || {};

  //   if (!numOfBedrooms) return null;

  //   if (numOfBedrooms === "Studio" || numOfBedrooms === "1 RK") {
  //     return numOfBedrooms;
  //   }

  //   return `${numOfBedrooms} BHK`;
  // };

  useEffect(() => {
    const propertiesList = async () => {
      let params = {
        city: cookies[config.preferencesCookie]?.city || "Bangalore",
      };
      const response = await invokeApi(
        config.apiDomains + apiList.allExclusiveProperties,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response?.data.responseCode === "200") {
          setPropertiesList(response.data.exclusiveProperties);
        } else {
          alert("An error occurred while fetching latest data!");
        }
      } else {
        alert("An error occurred while fetching latest data!!");
      }
    };
    if (isPropertiesFetching && !propertiesList.length > 0) {
      propertiesList();
      setIsPropertiesFetching(false);
    }
  }, [cookies, isPropertiesFetching]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1300) {
        setNoOfResult(4);
      } else if (window.innerWidth > 900) {
        setNoOfResult(3);
      } else if (window.innerWidth > 600) {
        setNoOfResult(2);
      } else if (window.innerWidth > 0) {
        setNoOfResult(1);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsPropertiesFetching(true);
  }, [cookies[config.preferencesCookie]?.city]);

  return (
    <>
      {/* latest property list start  */}
      <Box
        component="section"
        sx={{ py: { md: 10, xs: 5 } }}
        className="exclusive-property-section"
      >
        <Container maxWidth={"lg"}>
          <Box
            sx={{
              textAlign: "center",
              margin: "20px 0",
              position: "relative",
              zIndex: 1,
              "&::before": {
                content: '""',
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
                height: "150%",
                // backgroundColor: "#f2f2f2",
                zIndex: -1,
                borderRadius: "10px",
                // boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontWeight: "bold",
                fontSize: { md: "33px", xs: "20px" },
                position: "relative",
                zIndex: 2,
                background: "linear-gradient(90deg, #e74c3c 30%, #FFD700 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                display: "inline-block",
                padding: "10px 20px",
                borderRadius: "10px",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
                "& span": {
                  color: "#FFD700",
                  fontStyle: "italic",
                },
              }}
            >
              Exclusive Properties in {cookies[config.preferencesCookie]?.city}
            </Typography>
          </Box>
          <Swiper
            watchSlidesProgress={true}
            pagination={true}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            modules={[Pagination, Navigation]}
            slidesPerView={noOfResult}
            className="mySwiper"
          >
            {propertiesList.length <= 0
              ? Array.from({ length: 15 }, (_, index) => (
                <SwiperSlide key={index}>
                  <Card
                    sx={{
                      margin: "0 5px",
                      minHeight: "290px",
                      zIndex: 1,
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={150}
                    />
                    <CardContent
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          textAlign: "left",
                          pl: { md: "12px", xs: "5px" },
                          mt: "10px",
                          mb: "4px",
                        }}
                      >
                        <Box sx={{ pt: 0.5 }}>
                          <Skeleton />
                          <Skeleton width="60%" />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            gap: "10px",
                          }}
                        >
                          <Skeleton width={"100%"} height={60} />
                          <Skeleton width={"100%"} height={60} />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </SwiperSlide>
              ))
              : propertiesList?.map((property, index) => (
                <SwiperSlide key={index}>
                  <Card
                    sx={{
                      margin: "0 5px", // Apply margin for spacing
                      minHeight: "360px",
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        backgroundColor: "#FFD700",
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        borderRadius: "3px 0 0 3px",
                        color: "#fff",
                        fontWeight: "bold",
                        padding: "5px 20px 5px 23px",
                        fontSize: "12px",
                        "&::after": {
                          content: '""',
                          background: "transparent",
                          borderBottom: "13px solid transparent",
                          borderLeft: "10px solid #FFD700",
                          borderTop: "13px solid transparent",
                          position: "absolute",
                          right: "-10px",
                          top: "0",
                        },
                      }}
                      variant="subtitle2"
                    >
                      {property.listingType}
                    </Typography>

                    <CardContent
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: "0px",
                      }}
                    >
                      <Box className="property-image-container">
                        <Box
                          component="img"
                          src={
                            property.imageData?.find(
                              (item) => item.imageType === "featured"
                            )?.imagePath || emptyPropertyImage
                          }
                        />
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <Box className="property-title-container">
                          <Link
                            to={`/property/${formatSegment(
                              property.listingType
                            )}/${formatSegment(
                              property.propertyName
                            )}/${formatSegment(
                              property.buildingType +
                              "-" +
                              property.propertyType +
                              "-in-" +
                              property.locality +
                              "-" +
                              property.city
                            )}/${property.propertyId}`}
                          >
                            {property?.title}
                            {/* {property?.furnishingType}{" "}
                                  {displayBedrooms(property?.numOfBedrooms)}
                                  {property?.propertyType} for{" "}
                                  {property?.listingType} in{" "}
                                  {property?.locality + "," || " "}
                                  {" " + property?.city} */}
                          </Link>
                        </Box>
                        <Typography variant="caption">
                          {property.city}, {property.state}
                        </Typography>
                        <Box className="property-price-container">
                          <Typography className="property-price">
                            {PriceFormatter(property.rent)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          overflow: "auto",
                          mx: 1,
                          fontSize: "12px",
                          whiteSpace: "nowrap",
                          zIndex: 2,
                        }}
                      >
                        {property.numOfBedrooms && (
                          <Box
                            sx={{
                              backgroundColor: "#EAEAEA",
                              display: "inline-block",
                              px: 1.5,
                              py: 0.8,
                              borderRadius: "8px",
                              mr: 1,
                            }}
                          >
                            {property.numOfBedrooms !== "Studio" ||
                              property.numOfBedrooms !== "1 RK"
                              ? property.numOfBedrooms + " BHK"
                              : property.numOfBedrooms}
                          </Box>
                        )}
                        {property.superBuiltupArea && (
                          <Box
                            sx={{
                              backgroundColor: "#EAEAEA",
                              display: "inline-block",
                              px: 1.5,
                              py: 0.8,
                              borderRadius: "8px",
                              mr: 1,
                            }}
                          >
                            {property.superBuiltupArea +
                              " " +
                              property.areaUnit}
                          </Box>
                        )}
                        {property.furnishingType && (
                          <Box
                            sx={{
                              backgroundColor: "#EAEAEA",
                              display: "inline-block",
                              px: 1.5,
                              py: 0.7,
                              borderRadius: "8px",
                              mr: 1,
                            }}
                          >
                            {property.furnishingType}
                          </Box>
                        )}
                        {property.positionStatus && (
                          <Box
                            sx={{
                              backgroundColor: "#EAEAEA",
                              display: "inline-block",
                              px: 1.5,
                              py: 0.7,
                              borderRadius: "8px",
                              mr: 1,
                            }}
                          >
                            {property.positionStatus}
                          </Box>
                        )}
                      </Box>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="contained"
                        size="small"
                        endIcon={<QuestionAnswerIcon />}
                        sx={{
                          fontSize: { xs: "12px", md: "12px" },
                          textTransform: "capitalize",
                          backgroundColor: "#EAEAEA",
                          color: "#000",
                          "&:hover": {
                            color: "#000",
                            backgroundColor: "#EAEAEA",
                          },
                        }}
                        onClick={() => {
                          handeChangeModal();
                          setPropertyId(property.propertyId);
                          setLeadSource(property.propertyType);
                        }}
                        fullWidth
                      >
                        Enquiry Now
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        endIcon={<EastIcon />}
                        sx={{
                          fontSize: { xs: "12px", md: "12px" },
                          textTransform: "capitalize",
                          backgroundColor: "#DB282F",
                          color: "#fff",
                          "&:hover": {
                            color: "#fff",
                            backgroundColor: "#DB282F",
                          },
                        }}
                        onClick={() =>
                          navigate(
                            `/property/${formatSegment(
                              property.listingType
                            )}/${formatSegment(
                              property.propertyName
                            )}/${formatSegment(
                              property.buildingType +
                              "-" +
                              property.propertyType +
                              "-in-" +
                              property.locality +
                              "-" +
                              property.city
                            )}/${property.propertyId}`
                          )
                        }
                        fullWidth
                      >
                        More Details
                      </Button>
                    </CardActions>
                  </Card>
                </SwiperSlide>
              ))}
            <div className="swiper-button-prev" />
            <div className="swiper-button-next" />
          </Swiper>
        </Container>
      </Box>
      {/* latest property list end  */}

      <LeadModal
        initialValue={leadModal}
        handleModal={handeChangeModal}
        leadSource={leadSource}
        propertyId={propertyId}
      />
    </>
  );
};

export default ExclusivePropertySection;
