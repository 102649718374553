import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { useDispatch, useSelector } from "react-redux";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { getLocation, getUser } from "../../../global/redux/action";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { config } from "../../../config/config";
import { Bounce, toast } from "react-toastify";
import { Badge } from "@mui/material";

const openedMixin = (theme) => ({
  width: 240,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 240,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DashBoardNavbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const { userData, userError, isFetching } = useSelector(
    (state) => state.user
  );
  const { locationData, locationError, isLocationFetching } = useSelector(
    (state) => state.location
  );

  // const copyToClipboard = () => {
  //   const url = `${window.location.origin}/register-agent/${
  //     cookies[config.cookieName]?.loginUserId
  //   }`;
  //   navigator.clipboard
  //     .writeText(url)
  //     .then(() => {
  //       toast.success("Copied to clipboard!", {
  //         position: "top-center",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //         transition: Bounce,
  //       });
  //     })
  //     .catch(() => {
  //       toast.error("Failed to copy!", {
  //         position: "top-center",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //         transition: Bounce,
  //       });
  //     });
  // };

  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
    if (locationError) {
      alert(
        "Something went wrong while fetching Location details. Please try again later!"
      );
    }
  }, [userError, locationError]);

  useEffect(() => {
    if (!locationData?.countries && !isLocationFetching && !locationError) {
      dispatch(
        getLocation({ id: cookies[config.cookieName]?.loginUserId, cookies })
      );
    }
  }, [
    dispatch,
    cookies,
    locationError,
    locationData,
    isLocationFetching,
  ]);

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleTabChange = (path) => {
    navigate(path);
  };

  const currentPath = window.location.pathname;
  const userRole = userData?.user?.roles || [];

  const getMenuItemsBasedOnRole = (role) => {
    return [
      // {
      //   text: "Dashboard",
      //   icon:
      //     currentPath === "/dashboard" ? (
      //       <img
      //         src="/media/svg/active-dashboard.svg"
      //         width="18.508px"
      //         height="18.508px"
      //         alt="active"
      //       />
      //     ) : (
      //       <DashboardIcon />
      //     ),
      //   path: "/dashboard",
      // },
      // eg
      {
        text: "Property Listing",
        icon:
          currentPath === "/post-property" ? (
            <HomeWorkIcon sx={{ color: "#405189" }} />
          ) : (
            <HomeWorkIcon />
          ),
        path: "/post-property",
      },
      {
        text: "Lead Manager",
        icon:
          currentPath === "/leads-list" ? (
            <img
              src="/media/svg/active-lead-manager.svg"
              width="20.045px"
              height="18.508px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/lead-manager.svg"
              width="20.045px"
              height="18.508px"
              alt="lead Manager"
            />
          ),
        path: "/leads-list",
      },
      {
        text: "Employee Manager",
        icon:
          currentPath === "/user-add" ? (
            <img
              src="/media/svg/active-employee-manager.svg"
              width="24.165px"
              height="24.736px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/employee-manager.svg"
              width="24.165px"
              height="24.736px"
              alt="employee manager"
            />
          ),
        path: "/user-add",
      },
      // {
      //   text: "Package Manager",
      //   icon:
      //     currentPath === "/add-package" ? (
      //       <img
      //         src="/media/svg/package1.svg"
      //         width="20.583px"
      //         height="20.583px"
      //         alt="active"
      //       />
      //     ) : (
      //       <img
      //         src="/media/svg/package1.svg"
      //         width="20.583px"
      //         height="20.583px"
      //         alt="Blog"
      //       />
      //     ),
      //   path: "/add-package",
      // },
      // {
      //   text: "Buy Package",
      //   icon:
      //     currentPath === "/my-packages" ? (
      //       <img
      //         src="/media/svg/package1.svg"
      //         width="17.583px"
      //         height="17.583px"
      //         alt="active"
      //       />
      //     ) : (
      //       <img
      //         src="/media/svg/package1.svg"
      //         width="17.583px"
      //         height="17.583px"
      //         alt="Blog"
      //       />
      //     ),
      //   path: "/my-packages",
      // },
      {
        text: "Blog Manager",
        icon:
          currentPath === "/add-blog" ? (
            <img
              src="/media/svg/active-blog.svg"
              width="17.583px"
              height="17.583px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/blog.svg"
              width="17.583px"
              height="17.583px"
              alt="Blog"
            />
          ),
        path: "/add-blog",
      },
      {
        text: "Create Lead",
        icon:
          currentPath === "/create-lead" ? (
            <img
              src="/media/svg/active-add-circle-svgrepo-com.svg"
              width="22.583px"
              height="22.583px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/add-circle-svgrepo-com.svg"
              width="22.583px"
              height="22.583px"
              alt="Create Lead"
            />
          ),
        path: "/create-lead",
      },
      {
        text: "Operator",
        icon:
          currentPath === "/operator-associate-list" ? (
            <img
              src="/media/svg/operator.svg"
              width="22.583px"
              height="22.583px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/operator.svg"
              width="22.583px"
              height="22.583px"
              alt="Operator"
            />
          ),
        path: "/operator-associate-list",
      },
      {
        text: "Contact Us Data",
        icon:
          currentPath === "/contact-us-data" ? (
            <img
              src="/media/svg/call-icon.svg"
              width="22.583px"
              height="22.583px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/call-icon.svg"
              width="22.583px"
              height="22.583px"
              alt="Operator"
            />
          ),
        path: "/contact-us-data",
      },
      {
        text: "Address Manager",
        icon:
          currentPath === "/address-manager" ? (
            <img
              src="/media/svg/address.svg"
              width="35.583px"
              height="35.583px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/address.svg"
              width="35.583px"
              height="35.583px"
              alt="Operator"
            />
          ),
        path: "/address-manager",
      },
      // Add more items as needed
    ].filter((item) => {
      switch (true) {
        case userRole.includes("Admin"):
          return item.text !== "Operator";
        case userRole.includes("Associate"):
          return [
            "Lead Manager",
            "Blog Manager",
            "Property Listing",
            "Create Lead",
            "Address Manager",
          ].includes(item.text);
        case userRole.includes("Agent"):
          return ["Lead Manager", "Property Listing", "Create Lead"].includes(
            item.text
          );
        case userRole.includes("Telecaller"):
          return [
            "Lead Manager",
            "Property Listing",
            "Create Lead",
            "Contact Us Data",
          ].includes(item.text);
        case userRole.includes("Team Leader"):
          return ["Lead Manager", "Property Listing", "Create Lead"].includes(
            item.text
          );
        case userRole.includes("Operator"):
          return ["Operator", "Employee Manager"].includes(item.text);
        default:
          return false;
      }
    });
  };

  const menuItems = getMenuItemsBasedOnRole(userRole);

  return (
    <>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {theme.direction === "rtl" ? (
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <>
              {open && <Typography variant="h5">Get My Housing</Typography>}
              <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </>
          )}
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems?.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => handleTabChange(item.path)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
          {/* {(userRole?.includes("Associate") || userRole?.includes("Admin")) && (
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => copyToClipboard()}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Badge
                    badgeContent={userData?.user?.totalRefered}
                    color="primary"
                    overlap="circular"
                  >
                    <img
                      src="/media/svg/refer.svg"
                      width="22.583px"
                      height="22.583px"
                      alt="active"
                    />
                  </Badge>
                </ListItemIcon>
                <ListItemText
                  primary="Refer Agent"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          )} */}
        </List>
      </Drawer>
    </>
  );
};

export default DashBoardNavbar;
