import React, { useState } from 'react'
import image from "../assets/property-management-enquiry.jpg";
import { Autocomplete, Box, Card, Container, Grid, TextField, Typography } from '@mui/material';
import { Bounce, toast } from 'react-toastify';
import { config } from '../../../../config/config';
import { apiList, invokeApi } from '../../../../apis/apiServices';
import { useSelector } from 'react-redux';
import mobileNoValidation from '../../../../common/common';
import { useCookies } from 'react-cookie';

const EnquirySection = ({ leadSource }) => {
    const [cookies] = useCookies();
    const { userData } = useSelector(
        (state) => state.user
    );
    const { locationData } = useSelector(
        (state) => state.location
    );
    const zoneMappingData = locationData?.countries;
    const [formData, setFormData] = useState({
        email: userData?.user?.email || "",
        customerName: userData?.user?.fullName || "",
        mobileNumber: userData?.user?.mobileNumber || "",
        leadSource,
        leadType: "Own",
        customerDistrict: userData?.user?.district || "",
        customerState: userData?.user?.state || "",
    })
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    let mobileValidate = mobileNoValidation(formData.mobileNumber);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        setErrors({ ...errors, [e.target.name]: "" })
    };

    // Validate form
    const validateForm = () => {
        let newErrors = {};

        if (!formData.customerName.trim()) newErrors.customerName = "Full Name is required.";

        // Mobile number validation (10 digits)
        if (!mobileValidate) {
            newErrors.mobileNumber = "Mobile Number is required.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const addLead = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        setIsSubmitting(true);

        try {
            const response = await invokeApi(
                config.apiDomains + apiList.addLead,
                formData,
                cookies
            );

            if (response) {
                if (
                    response.status === "200" ||
                    response.data.responseMessage === "Successful"
                ) {
                    toast.success(
                        "Thank you for showing interest we will contact you soon",
                        {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Bounce,
                        }
                    );
                    setFormData({
                        email: "",
                        customerName: "",
                        mobileNumber: "",
                        leadSource: "Property Management",
                        leadType: "Own",
                        customerDistrict: "",
                        customerState: "",
                    })
                    setIsSubmitting(false);
                } else {
                    toast.error("Sorry Something went wrong", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                    setIsSubmitting(false);
                }
            } else {
                alert("Something Went Wrong");
                setIsSubmitting(false);
            }
        } catch (error) {
            toast.error("An error occurred while fetching location!", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setIsSubmitting(false);
        }
    };
    return (
        <section style={{ padding: "50px 0", backgroundColor: "#f8f9fa" }}>
            <Container>
                <Card sx={{ p: 4, boxShadow: 3, borderRadius: 2 }}>
                    <Grid container spacing={3} alignItems="center">
                        {/* Left: Enquiry Form */}
                        <Grid item xs={12} md={6}>
                            <form style={{ px: 3 }} onSubmit={addLead}>
                                <Typography variant="h5" fontWeight="bold" gutterBottom>
                                    Get in Touch
                                </Typography>

                                <Typography variant="body2" color="textSecondary" mb={2}>
                                    Fill in the details and our team will contact you soon.
                                </Typography>

                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Full Name"
                                    placeholder="Enter Your Name"
                                    margin="normal"
                                    name="customerName"
                                    type="text"
                                    autoComplete="off"
                                    value={formData.customerName}
                                    onChange={handleChange}
                                />
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="mobileNumber"
                                    margin="normal"
                                    autoComplete="off"
                                    label="Mobile Number"
                                    placeholder="Enter your mobile number"
                                    inputProps={{ maxLength: 10 }}
                                    value={formData.mobileNumber}
                                    onChange={handleChange}
                                />
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    type="email"
                                    margin="dense"
                                    name="email"
                                    autoComplete="off"
                                    label="Email"
                                    placeholder="Enter Your Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <Autocomplete
                                    id="outlined-select-state"
                                    options={
                                        zoneMappingData
                                            ?.find((item) => item.countryName === "India")
                                            ?.states.map((ite) => ite.stateName) || []
                                    }
                                    value={formData.customerState}
                                    onChange={(event, newValue) => {
                                        setFormData({ ...formData, customerState: newValue });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label="Please select your state"
                                            variant="outlined"
                                            margin="normal"
                                        />
                                    )}
                                />

                                <Autocomplete
                                    id="outlined-select-district"
                                    options={
                                        (
                                            zoneMappingData
                                                ?.find((item) => item.countryName === "India")
                                                ?.states?.find(
                                                    (state) => state.stateName === formData.customerState
                                                )?.districts || []
                                        ).map((district) => district.districtName) || []
                                    }
                                    value={formData.customerDistrict}
                                    disabled={!formData.customerState}
                                    onChange={(event, newValue) => {
                                        setFormData({ ...formData, customerDistrict: newValue });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            margin="normal"
                                            label="Please select your city / district"
                                            variant="outlined"
                                        />
                                    )}
                                />

                                <button class="submit" type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "Submitting..." : "Request a Consultation"}
                                </button>
                            </form>
                        </Grid>

                        {/* Right: Image */}
                        <Grid item xs={12} md={6}>
                            <Box sx={{ overflow: "hidden", borderRadius: 2 }}>
                                <img src={image} alt="Enquiry" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "8px" }} />
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
            </Container>
        </section>
    )
}

export default EnquirySection