import "./PropertyManagementServices.css";
import { useParams } from "react-router-dom";
import Header from "../../../generic/Header";
import Footer from "../../../generic/Footer";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import backgroundImage from "../assets/property-management-hero.jpg";
import Faq from "../service-components/Faq";
import EnquirySection from "../service-components/EnquirySection";
import HeroSection from "../service-components/HeroSection";
import SubServices from "../service-components/SubServices";
import WhyChooseUs from "../service-components/WhyChooseUs";
import Testimonial from "../service-components/Testimonial";
import OurProcess from "../service-components/OurProcess";
import { services } from "../../../../common/common";


export const PropertyManagementServices = () => {
    const { city } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>Get My Housing - Property Management Service {city}</title>
                <meta
                    property="og:title"
                    content={`Get My Housing - Property Management Service ${city}`}
                />
                <meta property="og:url" content={window.location.href} />
                <link rel="canonical" href={window.location.href} />
                <meta
                    property="og:image"
                    content="https://getmyhousing.com/servicePhotos/PropertyManagement.webp"
                />
            </Helmet>
            <Header />

            <HeroSection leadSource="Property Management" bannerImage={backgroundImage} />


            <SubServices subServices={services[0].childService} />

            <WhyChooseUs />

            <Testimonial />

            <OurProcess />

            <EnquirySection leadSource="Property Management" />

            <Faq />

            <Footer />
        </>
    )
}