import { Box, Typography } from '@mui/material'
import React from 'react'
import { features } from '../../../../common/common'

const WhyChooseUs = () => {
    return (
        <Box component='section' className="why-choose-us">
            <Typography variant="h2">Why Choose GetMyHousing?</Typography>
            <Box className="features-wrapper">
                {features.map((feature, index) => (
                    <Box className="feature-card" key={index}>
                        <Box className="feature-icon">{feature.icon}</Box>
                        <h3 className="feature-title">{feature.title}</h3>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default WhyChooseUs