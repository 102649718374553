import { Avatar, Box, Card, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { processSteps } from '../../../../common/common'

const OurProcess = () => {
    return (
        <section style={{ backgroundColor: "#f8f9fa", padding: "50px 0" }}>
            <Container>
                <Box className="primary-heading-container">
                    <Typography variant="h2" className="section-primary-heading">
                        Our Process
                    </Typography>

                    <Typography variant="body1" color="textSecondary">
                        A simple and seamless way to get your dream home.
                    </Typography>
                    <Box
                        sx={{
                            width: "80px",
                            height: "4px",
                            backgroundColor: "#ff5252",
                            margin: "10px auto",
                            borderRadius: "2px",
                        }}
                    />
                </Box>
                <Grid container spacing={3} justifyContent="center">
                    {processSteps.map((step, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    padding: "20px",
                                    textAlign: "center",
                                    boxShadow: 3,
                                    borderRadius: 2,
                                }}
                            >
                                <Avatar
                                    src={step.icon}
                                    alt={step.title}
                                    sx={{ width: 60, height: 60, mb: 2 }}
                                />
                                <Typography variant="h6">{step.title}</Typography>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </section >
    )
}

export default OurProcess