import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material'
import React from 'react'
import { faqs } from '../../../../common/common'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Faq = () => {
    return (
        <section style={{ padding: "50px 0", backgroundColor: "#f8f9fa" }}>
            <Container>
                {/* Section Heading */}
                <Typography variant="h4" fontWeight="bold" textAlign="center" gutterBottom>
                    Frequently Asked Questions
                </Typography>

                {/* FAQ List */}
                {faqs.map((faq, index) => (
                    <Accordion key={index} sx={{ mb: 2, boxShadow: 2 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ fontWeight: "bold" }}>
                            {faq.question}
                        </AccordionSummary>
                        <AccordionDetails>{faq.answer}</AccordionDetails>
                    </Accordion>
                ))}
            </Container>
        </section>
    )
}

export default Faq