import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { services } from "../../../../common/common";
import Header from "../../../generic/Header";
import Footer from "../../../generic/Footer";
import commercial from "../assets/commercial-property-management-service.jpg";
import HeroSection from "../service-components/HeroSection";
import SubServices from "../service-components/SubServices";
import WhyChooseUs from "../service-components/WhyChooseUs";
import Testimonial from "../service-components/Testimonial";
import OurProcess from "../service-components/OurProcess";
import EnquirySection from "../service-components/EnquirySection";
import Faq from "../service-components/Faq";

export const CommercialPropertyManagement = () => {
  const { city } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const subServices = services[0].childService
    .filter((item) => item.title !== "Commercial Property Management");

  return (
    <>
      <Helmet>
        <title>
          Get My Housing - Commercial Property Management Service {city}
        </title>
        <meta
          property="og:title"
          content={`Get My Housing - Commercial Property Management Service ${city}`}
        />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
        <meta
          property="og:image"
          content="https://getmyhousing.com/servicePhotos/PropertyManagement.webp"
        />
      </Helmet>
      <Header />

      <HeroSection leadSource="Commercial Property Management" bannerImage={commercial} />

      <SubServices subServices={subServices} />

      <WhyChooseUs />

      <Testimonial />

      <OurProcess />

      <EnquirySection leadSource="Commercial Property Management" />

      <Faq />

      <Footer />
    </>
  );
};