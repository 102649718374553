import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import Footer from "../../../generic/Footer";
import Header from "../../../generic/Header";
import { Autocomplete, Box, Breadcrumbs, Paper } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import ScheduleIcon from "@mui/icons-material/Schedule";
import mobileNoValidation, {
  countryCodeList,
  services,
} from "../../../../common/common";
import SendIcon from "@mui/icons-material/Send";
import { Bounce, toast } from "react-toastify";
import {
  Grid,
  FormControl,
  InputAdornment,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { config } from "../../../../config/config";
import { apiList, invokeApi } from "../../../../apis/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { getLocation, getUser } from "../../../../global/redux/action";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import HeroSection from "../service-components/HeroSection";
import PaintingBanner from "../assets/painting-service.jpg"
import SubServices from "../service-components/SubServices";
import WhyChooseUs from "../service-components/WhyChooseUs";
import Testimonial from "../service-components/Testimonial";
import OurProcess from "../service-components/OurProcess";
import EnquirySection from "../service-components/EnquirySection";
import Faq from "../service-components/Faq";

export const PaintingService = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const subService = services[1].childService;

  return (
    <>
      <Helmet>
        <title>Get My Housing - Painting Service</title>
        <meta property="og:title" content="Get My Housing - Painting Service" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
        <meta
          property="og:image"
          content={`https://getmyhousing.com/servicePhotos/PaintingService.webp`}
        />
      </Helmet>
      <Header />

      <HeroSection leadSource="Painting Service" bannerImage={PaintingBanner} />

      <SubServices subServices={subService} />

      <WhyChooseUs />

      <Testimonial />

      <OurProcess />

      <EnquirySection leadSource="Painting Service" />

      <Faq />


      <Footer />
    </>
  );
};