import React, { useEffect, useState } from "react";
import { apiList, invokeApi } from "../../apis/apiServices";
import { config } from "../../config/config";
import { useCookies } from "react-cookie";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  FormControl,
  InputLabel,
  InputAdornment,
  Select,
  MenuItem,
  TextField,
  InputBase,
  Typography,
  OutlinedInput,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { Bounce, toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import ScheduleIcon from "@mui/icons-material/Schedule";
import mobileNoValidation, { countryCodeList } from "../../common/common";
import { useDispatch, useSelector } from "react-redux";
import { getLocation, getUser } from "../../global/redux/action";

// Styled Components
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "12px",
    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  m: 0,
  p: theme.spacing(2),
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  color: "#fff",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderTopLeftRadius: "12px",
  borderTopRightRadius: "12px",
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#fff",
  borderRadius: "0 0 12px 12px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "linear-gradient(45deg, #FE6B8B, #FF8E53)",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: "#fff",
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "10px",
  },
  "& .MuiInputAdornment-root": {
    color: theme.palette.primary.main,
  },
  "& input": {
    paddingLeft: theme.spacing(1),
  },
}));

const StyledTextFieldMobileNo = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "10px",
  },
  "& .MuiInputAdornment-root": {
    color: theme.palette.primary.main,
  },
  "& input": {
    paddingLeft: theme.spacing(1),
  },
  "& .MuiInputBase-adornedStart": {
    paddingLeft: "0px",
  },
}));

const LeadModal = ({
  initialValue,
  handleModal,
  leadSource,
  propertyId,
  productUrl,
  leadProvided,
}) => {
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const { userData, userError, isFetching } = useSelector(
    (state) => state.user
  );
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  const [leadLoading, setleadLoading] = useState(false);
  const [email, setEmail] = useState(userData?.user?.email || "");
  const [customerName, setcustomerName] = useState(
    userData?.user?.fullName || ""
  );
  const [mobileNumber, setMobileNumber] = useState(
    userData?.user?.mobileNumber || ""
  );

  let mobileValidate = mobileNoValidation(mobileNumber);

  const [scheduleDateTime, setScheduleDateTime] = useState(null);
  const [countryCode, setCountryCode] = useState(
    userData?.user?.countryCode || "91"
  );

  // below the state is error and helpertext
  const [mobileError, setMobileError] = useState(false);
  const [mobileHelperText, setMobileHelperText] = useState("");

  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");

  const [scheduleError, setScheduleError] = useState(false);
  const [scheduleHelperText, setScheduleHelperText] = useState("");

  //   addlead
  const addLead = async () => {
    setleadLoading(true);
    let validate = true;
    if (!mobileValidate) {
      setMobileError(true);
      setMobileHelperText("Please Enter Valid Mobile Number");
      setleadLoading(false);
      validate = false;
    }
    if (!customerName) {
      setNameError(true);
      setNameHelperText("Please Enter Your Name");
      setleadLoading(false);
      validate = false;
    }
    if (!email) {
      setEmailError(true);
      setEmailHelperText("Please Enter Valid Email Id");
      setleadLoading(false);
      validate = false;
    }
    if (!scheduleDateTime) {
      setScheduleError(true);
      setScheduleHelperText("Please Select Date Time To right Time to Call");
      setleadLoading(false);
      validate = false;
    }
    if (validate) {
      let params = {
        email,
        customerName,
        mobileNumber,
        propertyId: propertyId,
        leadSource: leadSource,
        leadType: "Own",
        scheduleDateTime,
        countryCode: countryCode,
        productUrl,
      };
      const response = await invokeApi(
        config.apiDomains + apiList.addLead,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success(
            "Thank you for showing interest we will contact you soon",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            }
          );
          setleadLoading(false);
          handleModal();
          setcustomerName("");
          setMobileNumber("");
          setEmail("");
          // leadProvided(propertyId);
        } else {
          toast.error("Sorry Something went wrong", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          setleadLoading(false);
          setcustomerName("");
          setMobileNumber("");
          setEmail("");
        }
      } else if (response.status === 400) {
        toast.info(response.data.responseMessage, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setleadLoading(false);
      } else {
        toast.error("Sorry Something went wrong", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setleadLoading(false);
      }
    }
  };
  return (
    <>
      <BootstrapDialog
        open={initialValue}
        onClose={handleModal}
        maxWidth="xs"
        fullWidth
      >
        <StyledDialogTitle id="customized-dialog-title">
          Contact Details
          <IconButton aria-label="close" onClick={handleModal}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <StyledDialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                Full Name
              </Typography>
              <StyledTextField
                variant="outlined"
                fullWidth
                placeholder="Enter Your Name"
                margin="dense"
                error={nameError}
                helperText={nameHelperText}
                type="text"
                value={customerName || ""}
                onChange={(event) => {
                  setcustomerName(event.target.value);
                  setNameError(false);
                  setNameHelperText("");
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Mobile Number</Typography>
              <StyledTextFieldMobileNo
                variant="outlined"
                fullWidth
                margin="dense"
                placeholder="Enter your mobile number"
                inputProps={{ maxLength: 10 }}
                error={mobileError}
                helperText={mobileHelperText}
                value={mobileNumber || ""}
                onChange={(event) => {
                  setMobileNumber(event.target.value);
                  setMobileError(false);
                  setMobileHelperText("");
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FormControl variant="outlined">
                        <Select
                          labelId="country-code-select-label"
                          id="country-code-select"
                          value={countryCode || ""}
                          onChange={(event) =>
                            setCountryCode(event.target.value)
                          }
                        >
                          {countryCodeList?.map((item, index) => (
                            <MenuItem key={index} value={item.code}>
                              {"+ " + item.code}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                Email
              </Typography>
              <StyledTextField
                variant="outlined"
                fullWidth
                type="email"
                margin="dense"
                placeholder="Enter Your Email"
                error={emailError}
                helperText={emailHelperText}
                value={email || ""}
                onChange={(event) => {
                  setEmail(event.target.value);
                  setEmailError(false);
                  setEmailHelperText("");
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                Schedule Date & Time
              </Typography>
              <StyledTextField
                id="scheduleDateTime"
                type="datetime-local"
                placeholder="Schedule Date & Time"
                error={scheduleError}
                helperText={scheduleHelperText}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={scheduleDateTime || ""}
                onChange={(e) => {
                  setScheduleDateTime(e.target.value);
                  setScheduleError(false);
                  setScheduleHelperText("");
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ScheduleIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </StyledDialogContent>
        <StyledDialogActions>
          <LoadingButton
            size="large"
            onClick={addLead}
            endIcon={<SendIcon />}
            loading={leadLoading}
            loadingPosition="end"
            variant="contained"
            sx={{
              backgroundColor: "#f44336",
              color: "#FFF",
              "&:hover": {
                backgroundColor: "#d32f2f",
              },
              "&:disabled": {
                backgroundColor: "#ef9a9a",
                color: "#616161",
              },
            }}
          >
            Submit
          </LoadingButton>
        </StyledDialogActions>
      </BootstrapDialog>
    </>
  );
};

export default LeadModal;
