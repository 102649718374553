import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { services } from "../../common/common";
import { useCookies } from "react-cookie";
import { config } from "../../config/config";

const Service = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies();
  return (
    <>
      <Box component="section" sx={{ py: { md: 8, xs: 5 } }}>
        <Box className="primary-heading-container">
          <Typography className="section-primary-heading">Services</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            width: { md: "89%", xs: "100%" },
            m: "auto",
            gap: "20px",
          }}
        >
          {services.map((items, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
                justifyContent: "space-around",
                alignItems: "center",
                gap: { md: "20px", xs: "0px" },
                width: { md: "201px", xs: "101px" },
                height: { md: "156px", xs: "101px" },
                padding: "20px",
                borderRadius: "10px",
                background: "#FFF",
                boxShadow: "10px 10px 20px #c4c4c4, -10px -10px 20px #ffffff",
                transition: "transform 0.2s ease-in-out", // Smooth hover effect
                "&:hover": {
                  transform: "scale(1.10)", // Scale up on hover
                },
                "&:hover .line": {
                  width: "80%", // Full width on Card hover
                },
              }}
              onClick={() => {
                navigate(
                  items.navigate +
                  cookies[config.preferencesCookie]?.city.toLowerCase()
                );
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  maxHeight: { md: "100%", xs: "50%" },
                  maxWidth: { md: "100%", xs: "50%" },
                }}
              >
                <img src={items.icon} alt={items.name} height="30px" />
              </Box>
              <Box
                className="line"
                sx={{
                  width: "60px",
                  height: "2px",
                  transition: "width 0.3s ease",
                  flexShrink: 0,
                  background: "#E0E3EB",
                  my: 1,
                }}
              ></Box>
              <Typography
                sx={{
                  color: "#555656",
                  textAlign: "center",
                  fontFamily: "Mulish, sans-serif",
                  fontSize: { md: "16px", xs: "12px" },
                  fontWeight: 500,
                }}
              >
                {items.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Service;
