const actionTypes = {
  USER_GET_REQUEST: "USER_GET_REQUEST",
  USER_GET_SUCCESS: "USER_GET_SUCCESS",
  USER_GET_FAILURE: "USER_GET_FAILURE",

  LOCATION_GET_REQUEST: "LOCATION_GET_REQUEST",
  LOCATION_GET_SUCCESS: "LOCATION_GET_SUCCESS",
  LOCATION_GET_FAILURE: "LOCATION_GET_FAILURE",
  LOCATION_LOGOUT: "LOCATION_LOGOUT",

  CITIES_GET_REQUEST: "CITIES_GET_REQUEST",
  CITIES_GET_SUCCESS: "CITIES_GET_SUCCESS",
  CITIES_GET_FAILURE: "CITIES_GET_FAILURE",
  CITIES_LOGOUT: "CITIES_LOGOUT",

  LOGIN_DRAWER_REQUEST: "LOGIN_DRAWER_REQUEST",

  USER_LOGOUT: "USER_LOGOUT",

  RESET: "RESET",
};

export default actionTypes;
