import { Box, Chip, Divider, Grid, Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import { formatSegment, PriceFormatter } from '../../../../common/common';
import { Facebook, LinkedIn, Twitter, WhatsApp } from "@mui/icons-material";
import CallIcon from "@mui/icons-material/Call";

const PropertyCard = ({ index, listings, setPropertyId, setLeadModal, setLeadSource }) => {

    const handleImageError = (e) => {
        e.target.src = "/media/images/empty-property-image.jpg"; // Replace with the path to your default image
    };

    function callButtonHandle(propertyId, propertyType) {
        setPropertyId(propertyId);
        setLeadModal(true);
        setLeadSource(propertyType);
    }
    return (
        <Grid
            container
            key={index}
            spacing={2}
            sx={{
                display: "flex",
                flexDirection: { md: "row", xs: "column" },
                mb: 4,
                position: "relative",
                border: "1px solid #f14b4b",
                borderRadius: "15px",
                // p: { xs: 2 },
                transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                "&:hover": {
                    boxShadow: "0 1px 12px -4px rgba(0, 0, 0, 0.5)", // Red box shadow
                    transform: "scale(1.01)", // Slight zoom effect
                },
            }}
        >
            {/* below the grid item is include the proeprty image  */}
            <Grid item xs={12} md={4}>
                <Box
                    className={`property-main-image-container ${listings.approvalStatus === "Completed"
                        ? "completed-property"
                        : null
                        }`}
                >
                    <Link
                        to={`/property/${formatSegment(
                            listings.listingType
                        )}/${formatSegment(
                            listings.propertyName
                        )}/${formatSegment(
                            listings.buildingType +
                            "-" +
                            listings.propertyType +
                            "-in-" +
                            listings.locality +
                            "-" +
                            listings.city
                        )}/${listings.propertyId}`}
                    >
                        <img
                            src={listings.imageUrl}
                            loading="lazy"
                            alt="Property images"
                            onError={handleImageError}
                        />
                        {listings.approvalStatus === "Completed" && (
                            <div className="watermark">Not Available</div>
                        )}
                    </Link>
                </Box>
            </Grid>
            <Grid item xs={12} md={8}>
                <Link
                    to={`/property/${formatSegment(
                        listings.listingType
                    )}/${formatSegment(
                        listings.propertyName
                    )}/${formatSegment(
                        listings.buildingType +
                        "-" +
                        listings.propertyType +
                        "-in-" +
                        listings.locality +
                        "-" +
                        listings.city
                    )}/${listings.propertyId}`}
                    style={{
                        textDecoration: "none",
                    }}
                >
                    {/* below the typography have the title of the property like listingtype, property type */}
                    <Box className="property-title-container">
                        <Typography variant="h1">
                            {listings?.title}
                            {/* {listings?.furnishingType}{" "}
                                {displayBedrooms(listings?.numOfBedrooms)}
                                {listings?.propertyType} for{" "}
                                {listings?.listingType} in{" "}
                                {listings?.locality + "," || " "}
                                {" " + listings?.city} */}
                        </Typography>
                    </Box>
                    {/* below the box includes the property name , address, and price details  */}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: "Mulish, sans-serif",
                                    color: "var(--BG-Color-5, #DB282F)",
                                    fontSize: { md: "14px", xs: "13px" },
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "normal",
                                    mt: "10px",
                                }}
                            >
                                {listings.propertyName}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Mulish, sans-serif",
                                    color: "var(--Text-Color-Grey-Bold, #333)",
                                    fontSize: { md: "14px", xs: "13.5px" },
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "normal",
                                    mt: 0.5,
                                }}
                            >
                                {listings.locality
                                    ? listings.locality + ", "
                                    : null}
                                {listings.city}
                                {listings.pincode
                                    ? " - " + listings.pincode
                                    : null}
                            </Typography>
                        </Box>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            color="red"
                        />
                        <Typography
                            variant="h6"
                            sx={{
                                color: "red",
                                fontWeight: 800,
                                fontSize: "15px",
                            }}
                        >
                            {PriceFormatter(listings.rent)}
                        </Typography>
                    </Box>
                </Link>
                <Box
                    sx={{
                        width: "100%",
                        overflow: "auto",
                        mx: 1,
                        mt: { md: 3, xs: 1 },
                        fontSize: "12px",
                        whiteSpace: "nowrap",
                        zIndex: 2,
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                        "-ms-overflow-style": "none", // IE and Edge
                        "scrollbar-width": "none", // Firefox
                    }}
                >
                    {listings.numOfBedrooms && (
                        <Box
                            sx={{
                                backgroundColor: "#EAEAEA",
                                display: "inline-block",
                                px: 1.5,
                                py: 0.8,
                                borderRadius: "8px",
                                mr: 1,
                            }}
                        >
                            {listings.numOfBedrooms !== "Studio" ||
                                listings.numOfBedrooms !== "1 RK"
                                ? listings.numOfBedrooms + " BHK"
                                : listings.numOfBedrooms}
                        </Box>
                    )}
                    {listings.superBuiltupArea && (
                        <Box
                            sx={{
                                backgroundColor: "#EAEAEA",
                                display: "inline-block",
                                px: 1.5,
                                py: 0.8,
                                borderRadius: "8px",
                                mr: 1,
                            }}
                        >
                            {listings.superBuiltupArea +
                                " " +
                                listings.areaUnit}
                        </Box>
                    )}
                    {listings.furnishingType && (
                        <Box
                            sx={{
                                backgroundColor: "#EAEAEA",
                                display: "inline-block",
                                px: 1.5,
                                py: 0.7,
                                borderRadius: "8px",
                                mr: 1,
                            }}
                        >
                            {listings.furnishingType}
                        </Box>
                    )}
                    {listings.positionStatus && (
                        <Box
                            sx={{
                                backgroundColor: "#EAEAEA",
                                display: "inline-block",
                                px: 1.5,
                                py: 0.7,
                                borderRadius: "8px",
                                mr: 1,
                            }}
                        >
                            {listings.positionStatus}
                        </Box>
                    )}
                </Box>
                {listings.includedGroupProperty?.length > 0 && (
                    <>
                        <TableContainer
                            component={Paper}
                            sx={{
                                maxWidth: "100%",
                                overflowX: "auto",
                                "&::-webkit-scrollbar": {
                                    height: "2px",
                                },
                                "&::-webkit-scrollbar-track": {
                                    background: "red",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "red",
                                    borderRadius: "25px",
                                },
                                "&::-webkit-scrollbar-thumb:hover": {
                                    background: "#0d47a1",
                                },
                                scrollbarColor: "#1976d2 #f1f1f1",
                                mt: 1,
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            height: 40,
                                            backgroundColor: "red",
                                        }}
                                    >
                                        <TableCell
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 700,
                                                padding: "5px",
                                                textAlign: "center",
                                                color: "white",
                                            }}
                                            colSpan={
                                                listings.includedGroupProperty
                                                    .length + 1
                                            }
                                        >
                                            Same Property (Other Options)
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ height: 40 }}>
                                        <TableCell
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 700,
                                                background: "#dfe6e9",
                                                padding: "5px",
                                                textAlign: "center",
                                            }}
                                        >
                                            BHK
                                        </TableCell>
                                        {listings.includedGroupProperty?.map(
                                            (item, index) => (
                                                <TableCell
                                                    key={index}
                                                    sx={{
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                        padding: "5px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {item?.numOfBedrooms
                                                        ? item?.numOfBedrooms !== "Studio"
                                                            ? item?.numOfBedrooms + " BHK "
                                                            : item?.numOfBedrooms ===
                                                                "Studio"
                                                                ? item?.numOfBedrooms
                                                                : item?.numOfBedrooms === "1 RK"
                                                                    ? item?.numOfBedrooms
                                                                    : null
                                                        : null}
                                                </TableCell>
                                            )
                                        )}
                                    </TableRow>
                                    <TableRow sx={{ height: 40 }}>
                                        <TableCell
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 700,
                                                background: "#dfe6e9",
                                                padding: "5px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Area
                                        </TableCell>
                                        {listings.includedGroupProperty?.map(
                                            (item, index) => (
                                                <TableCell
                                                    key={index}
                                                    sx={{
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                        padding: "5px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {item?.builtupPlotArea ||
                                                        item.carpetArea ||
                                                        item.salebleArea ||
                                                        item.superBuiltupArea ||
                                                        item.plotArea}{" "}
                                                    {item.areaUnit}
                                                </TableCell>
                                            )
                                        )}
                                    </TableRow>
                                    <TableRow sx={{ height: 40 }}>
                                        <TableCell
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 700,
                                                background: "#dfe6e9",
                                                padding: "5px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Price
                                        </TableCell>
                                        {listings.includedGroupProperty?.map(
                                            (item, index) => (
                                                <TableCell
                                                    key={index}
                                                    sx={{
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                        padding: "5px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {PriceFormatter(item.rent)}
                                                </TableCell>
                                            )
                                        )}
                                    </TableRow>
                                    <TableRow sx={{ height: 40 }}>
                                        <TableCell
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 700,
                                                background: "#dfe6e9",
                                                padding: "5px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Details
                                        </TableCell>
                                        {listings.includedGroupProperty?.map(
                                            (item, index) => (
                                                <TableCell
                                                    key={index}
                                                    sx={{
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                        padding: "5px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <Link
                                                        to={`/property/${formatSegment(item.listingType)}/${formatSegment(item.propertyName)}/${formatSegment(item.buildingType + "-" + item.propertyType + "-in-" + item.locality + "-" + item.city)}/${item.propertyId}`}
                                                        style={{ textDecoration: "none" }}
                                                    >
                                                        <Chip
                                                            label="View"
                                                            color="primary"
                                                            sx={{
                                                                fontSize: 10,
                                                                fontWeight: 600,
                                                                padding: "1px 3px",
                                                                borderRadius: "5px",
                                                                cursor: "pointer",
                                                            }}
                                                            clickable
                                                        />
                                                    </Link>
                                                </TableCell>
                                            )
                                        )}
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </Grid>
            <Grid container>
                <Grid
                    item
                    md={12}
                    xs={12}
                    sx={{ ml: 1, mb: 1, mt: 0.8 }}
                >
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box
                            component="img"
                            src="/media/images/Ellipse 11.png"
                            sx={{
                                width: { md: "52.294px", xs: "35px" },
                                height: { md: "52.294px", xs: "35px" },
                                borderRadius: "50%",
                                mr: 1.5,
                            }}
                        ></Box>
                        <Box sx={{ flexGrow: 1, m: "auto" }}>
                            <Box
                                sx={{
                                    color: "var(--Text-Color-Grey-Bold, #333)",
                                    fontFamily: "Mulish, sans-serif",
                                    fontSize: { md: "18px", xs: "12px" },
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "normal",
                                }}
                            >
                                {listings?.username}
                            </Box>
                            <Box
                                sx={{
                                    color: "var(--Text-Color-Grey-Bold, #333)",
                                    fontFamily: "Mulish, sans-serif",
                                    fontSize: { md: "10px", xs: "9px" },
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "normal",
                                    mt: 0.3,
                                }}
                            >
                                {listings?.userRole !== "Agent" &&
                                    listings?.userRole}
                            </Box>
                        </Box>
                        {listings.approvalStatus === "Completed" && (
                            <div className="watermark-highlight">
                                Not Available
                            </div>
                        )}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: "auto",
                                mr: 1,
                                alignContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Link
                                to={`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin +
                                    "/property/" +
                                    formatSegment(listings.listingType) +
                                    "/" +
                                    formatSegment(listings.propertyName) +
                                    "/" +
                                    formatSegment(
                                        listings.buildingType +
                                        "-" +
                                        listings.propertyType +
                                        "-in-" +
                                        listings.locality +
                                        "-" +
                                        listings.city
                                    ) +
                                    "/" +
                                    listings.propertyId
                                    }`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#365899" }}
                            >
                                <Facebook sx={{ mb: -0.8, ml: 4 }} />
                            </Link>
                            <Link
                                to={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.origin +
                                    "/property/" +
                                    formatSegment(listings.listingType) +
                                    "/" +
                                    formatSegment(listings.propertyName) +
                                    "/" +
                                    formatSegment(
                                        listings.buildingType +
                                        "-" +
                                        listings.propertyType +
                                        "-in-" +
                                        listings.locality +
                                        "-" +
                                        listings.city
                                    ) +
                                    "/" +
                                    listings.propertyId
                                    }`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#0a66c2" }}
                            >
                                <LinkedIn sx={{ mb: -0.8, ml: 1.5 }} />
                            </Link>
                            <Link
                                to={`https://twitter.com/intent/tweet?url=${window.location.origin +
                                    "/property/" +
                                    formatSegment(listings.listingType) +
                                    "/" +
                                    formatSegment(listings.propertyName) +
                                    "/" +
                                    formatSegment(
                                        listings.buildingType +
                                        "-" +
                                        listings.propertyType +
                                        "-in-" +
                                        listings.locality +
                                        "-" +
                                        listings.city
                                    ) +
                                    "/" +
                                    listings.propertyId
                                    }`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "rgb(29, 155, 240)" }}
                            >
                                <Twitter sx={{ mb: -0.8, ml: 1.5 }} />
                            </Link>
                            <Link
                                to={`https://api.whatsapp.com/send?phone=91${listings?.whatsappNumber || "9740074470"
                                    }&text=${window.location.origin +
                                    "/property/" +
                                    formatSegment(listings.listingType) +
                                    "/" +
                                    formatSegment(listings.propertyName) +
                                    "/" +
                                    formatSegment(
                                        listings.buildingType +
                                        "-" +
                                        listings.propertyType +
                                        "-in-" +
                                        listings.locality +
                                        "-" +
                                        listings.city
                                    ) +
                                    "/" +
                                    listings.propertyId
                                    }%0AHi! I'm intrested in ${listings.propertyName
                                    }.Can i have some more info please.`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#25d366" }}
                            >
                                <WhatsApp sx={{ mb: -0.8, ml: 1.5 }} />
                            </Link>
                            <Link
                                onClick={() => {
                                    callButtonHandle(
                                        listings?.propertyId,
                                        listings?.propertyType
                                    );
                                }}
                                style={{ color: "#0088cc" }}
                            >
                                <CallIcon sx={{ mb: -0.8, ml: 1.5, mr: 2 }} />
                            </Link>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PropertyCard